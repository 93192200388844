<template>
  <div>
    <div class="container">
      <div class="snb">
        <div class="snb-inner">
          <div class="loc-left">
            <ol></ol>
          </div>
        </div>
      </div>
      <div class="main-slider">
        <figure>
          <img
            src="@/assets/libs/images/main_slide01.png"
            alt="redwood Artificial intelligence-based personalized health care service platform "
          />
        </figure>
      </div>
      <div id="contents" class="contents clearfix">
        <section class="board-preview">
          <article>
            <h4>{{ $t('main.notice') }}</h4>
            <router-link to="/notice" class="link-more">
              {{ $t('common.more') }}
            </router-link>
            <ol>
              <li
                v-for="data in noticeList"
                :key="data.noticeNo"
                style="line-height: 20px;"
              >
                <a @click="fetchNoticeDetail(data.noticeNo)">
                  <span class="title " :class="data.recent">
                    <b>{{ data.noticeSj }}</b>
                    {{ data.noticeCn }}</span
                  >
                  <em class="date">{{
                    common_getDateString2(data.creatDt)
                  }}</em>
                </a>
              </li>
            </ol>
          </article>
          <article>
            <h4>{{ $t('main.qna') }}</h4>
            <router-link to="/qna" class="link-more">
              {{ $t('common.more') }}
            </router-link>

            <ol>
              <li v-for="data in qnaList" :key="data.questNo">
                <a @click="checkQnaIsSealed(data.questNo, data.isSealed)">
                  <span class="title " :class="data.recent">
                    <b>{{ data.questSj }}</b>
                    {{ data.questCn }}</span
                  >
                  <em class="date">{{
                    common_getDateString2(data.questDt)
                  }}</em>
                </a>
              </li>
            </ol>
          </article>
        </section>
        <section class="quick-links clearfix">
          <router-link to="/intro">
            <span>{{ $t('main.quickLink1') }}</span>
            <small>{{ $t('main.quickLink1-eng') }}</small>
          </router-link>

          <router-link to="/guide">
            <span>{{ $t('main.quickLink2') }}</span>
            <small>{{ $t('main.quickLink2-eng') }}</small>
          </router-link>

          <router-link to="/platform">
            <span>{{ $t('main.quickLink3') }}</span>
            <small>{{ $t('main.quickLink3-eng') }}</small>
          </router-link>

          <router-link to="/guide">
            <span>{{ $t('main.quickLink4') }}</span>
            <small>{{ $t('main.quickLink4-eng') }}</small>
          </router-link>
        </section>
      </div>
      <!--/contents-->
    </div>
    <!--/container-->
    <item-detail-popup
      v-if="detailPopup"
      :detailData="detailData"
      :title="title"
      :category="category"
      @close="detailPopup = false"
    >
      <template v-slot:head v-if="category === 'notice'">
        <dl>
          <dd>
            <span>{{ $t('main.notice-pop-head1') }}</span>
            <b>{{ common_getDateString(detailData.creatDt) }}</b>
          </dd>
          <dd>
            <span>{{ $t('main.notice-pop-head2') }}</span>
            <b>{{ detailData.rdcnt }}</b>
          </dd>
          <dd>
            <span>{{ $t('main.notice-pop-head3') }}</span>
            <span
              v-html="
                common_getFile(detailData.fileNm, detailData.noticeNo) || '-'
              "
            >
            </span>
          </dd>
        </dl>
      </template>
      <template v-slot:head v-else>
        <dl>
          <dd>
            <span>{{ $t('main.qna-pop-head1') }}</span>
            <b>{{ common_getMaskingName(detailData.qstnerNm) }}</b>
          </dd>
          <dd>
            <span>{{ $t('main.qna-pop-head2') }}</span>
            <b>{{ common_getMaskingEmail(detailData.qstnerEmail) }}</b>
          </dd>
          <dd>
            <span>{{ $t('main.qna-pop-head3') }}</span>
            <b>{{
              common_getMaskingPhoneNo(
                common_getTelephoneNoText(detailData.qstnerTlnum),
              )
            }}</b>
          </dd>
        </dl>
      </template>
    </item-detail-popup>
    <password-check-popup
      v-if="pwdCheckPopup"
      @close="pwdCheckPopup = false"
      :fetchApi="checkPassword"
      :selectedRow="selectedRow"
      @next="fetchQnaDetail(selectedRow)"
      category="qna"
    />
  </div>
</template>
<script>
import { fetchMainNotice, fetchMainQna } from '@/api/mainApi.js';
import { fetchNoticeDetail } from '@/api/noticeApi.js';
import { fetchQnaDetail, checkPassword } from '@/api/qnaApi.js';
import CommonMixins from '@/mixins/CommonMixins.js';
import ItemDetailPopup from './ItemDetailPopup';
import PasswordCheckPopup from '@/components/common/PasswordCheckPopup';

export default {
  mixins: [CommonMixins],
  components: { ItemDetailPopup, PasswordCheckPopup },
  created() {
    this.fetchMainNotice();
    this.fetchMainQna();
  },
  data() {
    return {
      noticeList: [],
      qnaList: [],
      detailData: {},
      title: '',
      selectedRow: '',
      category: '',
      detailPopup: false,
      pwdCheckPopup: false,
    };
  },
  methods: {
    // 공지사항 목록
    async fetchMainNotice() {
      const { data } = await fetchMainNotice();
      this.noticeList = data;
    },
    // 문의하기 목록
    async fetchMainQna() {
      const { data } = await fetchMainQna();
      this.qnaList = data;
    },
    // 공지 상세 데이터
    async fetchNoticeDetail(noticeNo) {
      const {
        data: { detail },
      } = await fetchNoticeDetail(noticeNo);
      this.category = 'notice';
      this.detailData = {
        itemSj: detail.noticeSj,
        itemCn: detail.noticeCn,
        creatDt: detail.creatDt,
        fileNm: detail.fileNm,
        rdcnt: detail.rdcnt,
        noticeNo,
      };
      this.title = this.$i18n.t('main.notice');
      this.detailPopup = true;
    },
    // 문의하기 비밀번호 체크
    checkQnaIsSealed(questNo, isSealed) {
      if (isSealed === 'Y') {
        this.selectedRow = questNo;
        //dialogs password check
        this.pwdCheckPopup = true;
      } else {
        this.fetchQnaDetail(questNo);
      }
    },
    // 문의 상세 데이터
    async fetchQnaDetail(questNo) {
      this.category = 'qna';
      this.pwdCheckPopup = false;
      const {
        data: { detail },
      } = await fetchQnaDetail(questNo);
      this.detailData = {
        itemSj: detail.questSj,
        itemCn: detail.questCnEscape,
        qstnerNm: detail.qstnerNm,
        qstnerEmail: detail.qstnerEmail,
        qstnerTlnum: detail.qstnerTlnum,
        rspnsCn: detail.rspnsCn,
      };
      this.title = this.$i18n.t('main.qna');
      this.detailPopup = true;
    },
    // 비밀번호 체크
    checkPassword(params) {
      return checkPassword(params);
    },
  },
};
</script>
<style lang=""></style>
