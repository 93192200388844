<template lang="">
  <div>
    <div class="pop-overlay" :class="active ? 'active' : ''"></div>
    <!--팝업 : 게시판 상세보기 -->
    <div class="popup-area large" :class="active ? 'active' : ''">
      <div class="pop-header">
        <h1 class="pop-title">{{ title }}</h1>
        <button class="pop-x alert_close_btn" @click="$emit('close')">
          Close
        </button>
      </div>
      <div class="pop-contents">
        <article class="board-view">
          <div class="view-head">
            <div class="view-head-left">
              <h4>{{ (detailData && detailData.itemSj) || '-' }}</h4>
            </div>
            <div class="view-head-right">
              <slot name="head" />
            </div>
          </div>
          <div v-html="replaceCn(detailData.itemCn)" class="view-body"></div>
          <!-- qna만  -->
          <div
            class="view-body-reply"
            v-if="category === 'qna' && detailData.rspnsCn !== ''"
          >
            <dl>
              <dt>answered</dt>
              <dd v-html="replaceCn(detailData.rspnsCn)"></dd>
            </dl>
          </div>
        </article>
        <!------------// 게시판 내용 end ------------------>
        <div class="btns-bottom">
          <button
            type="button"
            @click="$emit('close')"
            class="bt-md bt-black alert_close_btn"
          >
            Close
          </button>
        </div>
      </div>
      <!--/pop-contents-->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    category: {
      type: String,
    },
    detailData: {
      type: Object,
    },
  },

  mounted() {
    this.active = true;
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    replaceCn(data) {
      return data ? data.replaceAll('\n', '<br />') : '-';
    },
  },
};
</script>
<style lang=""></style>
