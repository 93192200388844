import { main } from './index';

function fetchMainNotice() {
  return main.post(`/noticelist`);
}

function fetchMainQna() {
  return main.post(`/qnalist`);
}

export { fetchMainNotice, fetchMainQna };
